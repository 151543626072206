import React from "react"
import { graphql } from "gatsby"
// import {container, contentWrapper, lineBreak, portrait} from "./about.module.scss"
import * as styles from "../components/HomePage/HomePage.module.scss"

import ReactMarkdown from "react-markdown"
import remarkUnwrapImages from "remark-unwrap-images";
import { getSrc  } from "gatsby-plugin-image";


const AboutPage = ({
	data: {
		allMarkdownRemark: { edges },
	},
}) => {
	let aboutContent = edges[0].node.frontmatter.aboutText
	//implement image check around the website is a good check
	{/* {aboutContent.image && <img src={aboutContent.image} alt="" />} */}

                let content = edges[0].node.frontmatter.content
                // const topImageSrc = getSrc(frontmatter.topImage)

                return(
                    <section>
                        {content?.map((section) => {
                          switch(true) {
                            case Boolean(section.type == 'fullImage'):
                              return (
                                <div className={styles.doubleImageWrap}>
                                  <img className={styles.fullImage} src={getSrc(section.fullImage)}/>
                                </div>
                              )
                            case Boolean(section.type == 'image'):
                              return (
                                <div className={styles.singleImageWrap}>
                                  <img key={section.image} src={getSrc(section.image)} alt="" />
                                </div>
                              )
                              case Boolean(section.type == 'windowText'):
                                return (
                                  <ReactMarkdown className={styles.windowText} children={section.windowText} > 
									{section.windowText}
								  </ReactMarkdown>
                                )
                                case Boolean(section.type == 'fullText'):
                                  return (
                                    <ReactMarkdown className={styles.fullText} children={section.fullText} >
										{section.fullText}
									</ReactMarkdown>
                                )
                                default: 
                                  return (
                                    <>Data type not recognized</>
                                  )
                            
                          }
                        })}


                    </section>
                )
}
export default AboutPage

export const query = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { title: { eq: "About" } } }) {
			edges {
				node {
				  frontmatter {
					content {
					  type
					  windowText
					  fullText
					  fullImage {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					  image {
						childImageSharp {
						  gatsbyImageData
						}
					  }
					}
				  }
				}
			}
		}
	}
`
